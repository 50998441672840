import request from '@/common/utils/request'
import Vue from "vue";

export function getParticipateLogsList(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/participate-logs/get-participate-logs-list',
    method: 'post',
    data
  })
}

