<template>
  <div>
    <el-row ref="search_form">
      <el-col :span="24">
        <div class="search-box">
          <el-form :inline="true" size="medium" :model="searchForm">
            <el-form-item label="">
              <el-input
                v-model="searchForm.username"
                size="mini"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="searchForm.userTypeId"
                size="mini"
                placeholder="请选择嘉宾类型"
              >
                <el-option
                  v-for="item in userTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="searchForm.yyCompanyId"
                size="mini"
                placeholder="请选择邀宾单位/部门"
              >
                <el-option
                  v-for="item in yyCompanyList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="searchForm.createUserId"
                size="mini"
                placeholder="请选择管理员"
              >
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.nickname"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="searchForm.hyId"
                size="mini"
                placeholder="请选择会议"
              >
                <el-option
                  v-for="item in plateList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="searchForm.status"
                size="mini"
                placeholder="请选择参会状态"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="searchForm.hotelId"
                size="mini"
                placeholder="请选择酒店"
              >
                <el-option
                  v-for="item in hotelList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="searchForm.qy"
                size="mini"
                placeholder="请选择权益"
              >
                <el-option
                  v-for="item in qyList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="">
              <el-input
                v-model="searchForm.phone"
                size="mini"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-input
                v-model="searchForm.post"
                size="mini"
                placeholder="请输入职务职级"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-input
                v-model="searchForm.company"
                size="mini"
                placeholder="请输入单位"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="searchForm.workGroup"
                size="mini"
                placeholder="请选择部门/组"
              >
                <el-option
                  v-for="item in workGroupList"
                  :key="item.value"
                  :label="item.title"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="searchForm.country"
                size="mini"
                placeholder="请选择国家"
              >
                <el-option
                  v-for="item in countryList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="searchForm.handlerId"
                size="mini"
                placeholder="请选择联络员"
              >
                <el-option
                  v-for="item in handlerUserList"
                  :key="item.id"
                  :label="item.nickname"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="修改时间:">
              <el-date-picker
                v-model="date.date3"
                size="mini"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="timestamp"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="创建时间:">
              <el-date-picker
                v-model="date.date4"
                size="mini"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="timestamp"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
          <el-form :inline="true" size="medium" :model="searchForm">
            <el-form-item label="">
              <el-select
                v-model="searchForm.xingchengZt"
                size="mini"
                placeholder="请选择行程状态"
              >
                <el-option
                  v-for="item in xingchengZtList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="抵青时间:">
              <el-date-picker
                v-model="date.date1"
                size="mini"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="timestamp"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="离青时间:">
              <el-date-picker
                v-model="date.date2"
                size="mini"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="timestamp"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item>
              <el-button size="mini" type="primary" @click="search"
                >查询</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button size="mini" type="primary" @click="clear"
                >清空</el-button
              >
            </el-form-item>

            <el-form-item>
              <el-button type="primary" size="mini" @click="exportLogExcel"
                >按搜索条件导出Excel</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>

    <el-table
      :cell-style="columnStyle"
      :data="tableData"
      ref="elTable"
      :header-cell-style="{ backgroundColor: '#f4f4f5', textAlign: 'center' }"
      :height="tableHeight"
      border
      style="width: 100%"
    >
      <el-table-column type="selection" align="center" width="55">
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="id"
        label="序号"
        type="index"
        width="80"
      >
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="sortBy"
        label="排序"
        min-width="80"
      >
        <template slot-scope="scope">
          <el-button
            slot="reference"
            type="text"
            size="small"
            style="color: #606266"
            @click="showEditSortBy(scope.row, scope.$index)"
            >{{ scope.row.sortBy }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="username"
        label="姓名"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column
        align="center"
        prop="company"
        label="单位"
        min-width="200"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="post"
        label="职务"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="userTypeName"
        label="嘉宾类型"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="yqrHyId"
        align="left"
        label="已确认板块"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="queren(scope.row, 1)"
            >已确认板块</el-button
          >

          <!-- <div v-html="parsePlate(scope.row, 1)"></div> -->
        </template>
      </el-table-column>
      <el-table-column
        prop="dqrHyId"
        align="left"
        label="待确认板块"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="queren(scope.row, 0)"
            >待确认板块</el-button
          >

          <!-- <div v-html="parsePlate(scope.row, 0)"></div> -->
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="yyCompanyName"
        label="邀约单位"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="status"
        label="参会状态"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <template v-if="scope.row.status == -1">取消</template>
          <template v-else-if="scope.row.status == 0">审核中</template>
          <template v-else-if="scope.row.status == 1">审核通过</template>
          <template v-else-if="scope.row.status == 2">暂不确定</template>
          <template v-else-if="scope.row.status == 3">确认参加</template>
          <template v-else-if="scope.row.status == -1">取消参加</template>
          <template v-else-if="scope.row.status == -2">嘉宾拒绝</template>
          <template v-else-if="scope.row.status == -3">已删除</template>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="note2"
        label="嘉宾活动安排备注"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="note"
        label="嘉宾个人需求备注"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column
        align="center"
        prop="xingchengZt"
        label="当前行程状态"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="hotelName"
        label="酒店"
        show-overflow-tooltip
      >
      </el-table-column>
      <!-- <el-table-column
        align="center"
        prop="yq"
        label=""
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <template v-if="scope.row.yq == 0">7天内未去过中高风险地区</template>
          <template v-else-if="scope.row.yq == 1"
            >7天内去过中高风险地区</template
          >
        </template>
      </el-table-column> -->
      <el-table-column
        align="center"
        prop="arriveTime"
        label="抵青时间"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ fmtDate2(scope.row.arriveTime) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="arriveMode"
        label="抵达方式"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="frequency"
        label="抵达航班/车次信息"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="carnum"
        label="抵达车牌"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="arriveStation"
        label="接站口"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="arriveTime"
        label="离青时间"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ fmtDate2(scope.row.backTime) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="backMode"
        label="抵达方式"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="backFrequency"
        label="抵达航班/车次信息"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="backCarnum"
        label="抵达车牌"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="lkdd"
        label="送站口"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column
        align="center"
        prop="createTime"
        label="创建时间"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ fmtDate(new Date(scope.row.createTime * 1000)) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="createUserName"
        label="创建人"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="updateTime"
        label="最新修改时间"
        min-width="200"
      >
        <template slot-scope="scope">
          {{ fmtDate(new Date(scope.row.updateTime * 1000)) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="updateUserName"
        label="最新修改人"
        show-overflow-tooltip
      >
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-row>
      <el-col :span="24" class="pagination-box">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </el-col>
    </el-row>
    <queren ref="queren" @fetch-data="getDataList" />
  </div>
</template>

<script>
import queren from "../modeltwo/canhuifour.vue"; //确认参加

import { validPhone } from "@/common/utils/validate.js";
import util from "@/common/utils/util.js";
import { getUserTypeList } from "../../../api/user_type";
import { getYycompanyList } from "../../../api/yy_company";
import { getParticipateLogsList } from "../../../api/participate_logs";
import { getSystemSet } from "../../../api/system_setting";
import { getHotelList } from "../../../api/hotel";
import { getAllUserList } from "../../../api/user";
import { getToken } from "../../../common/utils/auth";
import axios from "axios";

export default {
  name: "participateLogs",
  components: { queren },

  comments: {
    // VueCanvasPoster
  },
  data() {
    return {
      total: 0,
      pageSize: 20,
      currentPage: 1,
      tableData: [],
      tableHeight: 200,
      editFormVisible: false,
      loading: false,
      loadingDiv: null,
      searchForm: {
        cardId: "",
        phone: "",
        username: "",
        post: "",
        userTypeId: "",
        company: "",
        hyId: "",
        status: "",
        yyCompanyId: "",
        workGroup: "",
        hotelId: "",
        country: "",
        qy: "",
        handlerId: "",
        createUserId: "",
        xingchengZt: "",
        arriveTime1: "",
        arriveTime2: "",
        backTime1: "",
        backTime2: "",
        updateTime1: "",
        updateTime2: "",
        createTime1: "",
        createTime2: "",
        userTypeIdList: [],
      },

      date: {
        date1: "",
        date2: "",
        date3: "",
        date4: "",
      },
      plateList: [],
      statusList: [
        // 状态  -1取消  1通过 2暂不确定 -2嘉宾拒绝 -3删除嘉宾
        { value: "", label: "请选择参会状态" },
        { value: 0, label: "审核中" },
        { value: 1, label: "审核通过" },
        { value: 3, label: "确定参加" },
        { value: 2, label: "暂不确定" },
        { value: -1, label: "取消参加" },
        { value: -2, label: "嘉宾拒绝" },
      ],
      yyCompanyList: [],
      workGroupList: [],
      hotelList: [],
      countryList: [
        { value: "", label: "请选择国家" },
        { value: "中国", label: "中国" },
        { value: "非中国", label: "非中国" },
      ],
      qyList: [
        { value: "", label: "请选择权益" },
        { value: "食宿及城际交通", label: "食宿及城际交通" },
        { value: "食宿", label: "食宿" },
        { value: "仅含餐", label: "仅含餐" },
        { value: "全自费", label: "全自费" },
        // {value: '食宿补差', label: '食宿补差'},
        // {value: '住宿补差', label: '住宿补差'},
      ],
      handlerUserList: [],
      userList: [],
      xingchengZtList: [
        { value: "", label: "请选择行程状态" },
        { value: "在途", label: "在途" },
        { value: "到融创茂", label: "到融创茂" },
        { value: "入住酒店", label: "入住酒店" },
        { value: "已入场", label: "已入场" },
        { value: "离店", label: "离店" },
        { value: "离青", label: "离青" },
      ],
      userTypeList: [],
      address: [],
      selectValue: "",
      defultProp: {
        label: "name",
        value: "name",
        children: "sub",
      },
      isIndeterminate: false,
      checkAll: false,
      frontUserList: [],

      genPoster: false,
      yyhDialogVisible: false,
      genImgTitle: "",
      yyhImg: "",
      imgQrcodeContent: "",
      imgUserName: "",
      genYYHRow: null,
      painting: {
        width: `1080px`,
        height: `1890px`,
        background: "#f4f5f7",
        views: {},
      },
      yyInfoRow: "",
      yyInfoCode: "",
      yyInfoSMS: "",
      yyInfoSendStatus: "",
      plateWithGroupList: [],
      jzkList: [
        // 接/送站口
        { label: "胶东国际机场", value: "胶东国际机场" },
        { label: "青岛北站", value: "青岛北站" },
        { label: "青岛站", value: "青岛站" },
        { label: "青岛西站", value: "青岛西站" },
      ],

      editSortBy: {
        sortByEditValue: "",
        pid: "",
      },

      excelImp: {
        dialogVisible: false,
        loading: false,
        fileList: [],
        action: "",
        loadingDiv: "",
      },

      baseUrl: "",
      uploadActionUrl: "",
      avatarUploadLoadingDiv: "",
    };
  },
  watch: {
    "editForm.id": function (val) {
      if (this.editForm.id == 0) {
        this.editRules["password"] = [
          {
            required: true,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      } else {
        this.editRules["password"] = [
          {
            required: false,
            min: 6,
            message: "密码不能少于6个字符",
            trigger: "blur",
          },
        ];
      }
    },
  },
  mounted() {
    // for(let i=0;i<100;i++){
    //   this.editSortBy.v["v"+i] = true;
    // }

    this.init();

    this.getDataList(this.currentPage);

    let offsetHeight = this.$refs.search_form.$el.offsetHeight;
    if (!offsetHeight) {
      offsetHeight = 0;
    }

    this.$nextTick(() => {
      this.tableHeight =
        document.documentElement.clientHeight - 150 - offsetHeight;
    });
    window.onresize = () => {
      this.$nextTick(() => {
        this.tableHeight =
          document.documentElement.clientHeight - 150 - offsetHeight;
      });
    };

    // this.address = json;

    let t = getToken();
    this.excelImp.action = this.$adminApiUrl + "/participate/excel-imp?_t=" + t;
    this.baseUrl = this.$baseUrl;
    this.uploadActionUrl = this.$adminApiUrl + "/uploadToQiNiu?_t=" + t;
  },
  methods: {
    queren(row, status) {
      this.$refs["queren"].showEdit(row, status);
      // this.$refs["queren"].parsePlate(row, status);
    },
    columnStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 1 || columnIndex == 2 || columnIndex == 3) {
        return "background:	#FFF8DC;";
      }
    },
    clear() {
      for (let key in this.searchForm) {
        this.searchForm[key] = "";
      }
      for (let key in this.date) {
        this.date[key] = "";
      }
      this.searchForm.userTypeIdList = [];
      this.getDataList(1);
    },
    init() {
      getUserTypeList().then((res) => {
        if (res.code) {
          this.userTypeList = res.data;
          this.userTypeList.unshift({ name: "请选择嘉宾类型", id: "" });
        }
      });

      // getPlateList().then(res=>{
      //   if (res.code){
      //     this.plateList = res.data;
      //     this.plateList.unshift({title: "请选择会议", id:''})
      //   }
      // });
      getYycompanyList().then((res) => {
        if (res.code) {
          this.yyCompanyList = res.data;
          this.yyCompanyList.unshift({ name: "请选择邀宾单位/部门", id: "" });
        }
      });

      getSystemSet({ group: "work_group" }).then((res) => {
        if (res.code) {
          // this.yyCompanyList = res.data;
          this.workGroupList.unshift({ title: "请选择部门/组", value: "" });
          for (let i in res.data) {
            this.workGroupList.push({
              title: res.data[i].title,
              value: res.data[i].title,
            });
          }
        }
      });

      getHotelList().then((res) => {
        if (res.code) {
          this.hotelList = res.data;
          this.hotelList.unshift({ id: "", name: "请选择酒店" });
        }
      });

      getAllUserList().then((res) => {
        if (res.code) {
          this.userList = JSON.parse(JSON.stringify(res.data));
          this.handlerUserList = res.data;

          this.userList.unshift({ id: "", nickname: "请选择管理员" });
          this.handlerUserList.unshift({ id: "", nickname: "请选择联络员" });
        }
      });
    },
    parsePlate(row, status) {
      console.log(row);
      if (row != "" && row != null) {
        var theMeeting = "";
        console.log(row.yqrHyId);
        console.log(row.dqrHyId);
        if (status == 1) {
          theMeeting = row.yqrHyId;
          console.log(theMeeting);
        } else {
          theMeeting = row.dqrHyId;
        }
        if (theMeeting == "" || theMeeting == null) {
          return "";
        } else {
          var meeting = theMeeting.split(",");
          var str = "";
          console.log(this.plateList);
          if (this.plateList.length == "" || this.plateList.length == null) {
            return "";
          } else {
            var plateList = this.plateList;
            for (var i = 1; i < meeting.length; i++) {
              for (var j = 1; j < plateList.length; j++) {
                if (meeting[i] == plateList[j].id) {
                  if (status == 0) {
                    // if (plateList[i].status == 0){
                    str +=
                      "<div class='ellipsis'>" +
                      i +
                      ".<" +
                      plateList[i].title +
                      "></div>";

                    // }
                  } else {
                    // if (plateList[i].status != 0){
                    str +=
                      "<div class='ellipsis'>" +
                      i +
                      ".<" +
                      plateList[i].title +
                      "></div>";

                    // }
                  }
                }
              }
            }
          }
        }
        return str;
      } else {
        return "";
      }
    },

    fmtDate2(d2, fmt) {
      // yyyy-MM-dd hh:mm:ss
      if (util.isEmpty(d2) || d2 <= 0) {
        return "";
      }
      return this.fmtDate(new Date(d2 * 1000), fmt);
    },
    fmtDate(d, fmt) {
      // yyyy-MM-dd hh:mm:ss
      if (!fmt) {
        fmt = "yyyy-MM-dd hh:mm:ss";
      }
      var o = {
        "M+": d.getMonth() + 1, //月份
        "d+": d.getDate(), //日
        "h+": d.getHours(), //小时
        "m+": d.getMinutes(), //分
        "s+": d.getSeconds(), //秒
        "q+": Math.floor((d.getMonth() + 3) / 3), //季度
        S: d.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (d.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },
    getDataList(pageNum) {
      const loadingDiv = util.showLoading();

      let param = {
        pageSize: this.pageSize,
        pageNum: pageNum,
      };

      if (util.isNotEmpty(this.date.date1) && this.date.date1.length >= 2) {
        this.searchForm.arriveTime1 = this.date.date1[0] / 1000;
        this.searchForm.arriveTime2 = this.date.date1[1] / 1000;
      } else {
        this.searchForm.arriveTime1 = "";
        this.searchForm.arriveTime2 = "";
      }

      if (util.isNotEmpty(this.date.date2) && this.date.date2.length >= 2) {
        this.searchForm.backTime1 = this.date.date2[0] / 1000;
        this.searchForm.backTime2 = this.date.date2[1] / 1000;
      } else {
        this.searchForm.backTime1 = "";
        this.searchForm.backTime2 = "";
      }

      if (util.isNotEmpty(this.date.date3) && this.date.date3.length >= 2) {
        this.searchForm.updateTime1 = this.date.date3[0] / 1000;
        this.searchForm.updateTime2 = this.date.date3[1] / 1000;
      } else {
        this.searchForm.updateTime1 = "";
        this.searchForm.updateTime2 = "";
      }

      if (util.isNotEmpty(this.date.date4) && this.date.date4.length >= 2) {
        this.searchForm.createTime1 = this.date.date4[0] / 1000;
        this.searchForm.createTime2 = this.date.date4[1] / 1000;
      } else {
        this.searchForm.createTime1 = "";
        this.searchForm.createTime2 = "";
      }

      for (var i in this.searchForm) {
        param[i] = this.searchForm[i];
      }

      getParticipateLogsList(param)
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          } else if (res.code == 1) {
            let data = res.data;
            this.plateList = data.plateList;
            this.plateList.unshift({ title: "请选择会议", id: "" });
            this.currentPage = pageNum;
            this.total = data.total;
            this.tableData = data.dataList;
            if (data.plateList) {
              this.plateList = data.plateList;
            }
          }
          loadingDiv.close();
        })
        .catch((res) => {
          loadingDiv.close();
        });
    },
    exportLogExcel() {
      const loadingDiv = util.showLoading("正在导出EXCEL");

      let param = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      };

      if (util.isNotEmpty(this.date.date1) && this.date.date1.length >= 2) {
        this.searchForm.arriveTime1 = this.date.date1[0] / 1000;
        this.searchForm.arriveTime2 = this.date.date1[1] / 1000;
      } else {
        this.searchForm.arriveTime1 = "";
        this.searchForm.arriveTime2 = "";
      }

      if (util.isNotEmpty(this.date.date2) && this.date.date2.length >= 2) {
        this.searchForm.backTime1 = this.date.date2[0] / 1000;
        this.searchForm.backTime2 = this.date.date2[1] / 1000;
      } else {
        this.searchForm.backTime1 = "";
        this.searchForm.backTime2 = "";
      }

      if (util.isNotEmpty(this.date.date3) && this.date.date3.length >= 2) {
        this.searchForm.updateTime1 = this.date.date3[0] / 1000;
        this.searchForm.updateTime2 = this.date.date3[1] / 1000;
      } else {
        this.searchForm.updateTime1 = "";
        this.searchForm.updateTime2 = "";
      }

      if (util.isNotEmpty(this.date.date4) && this.date.date4.length >= 2) {
        this.searchForm.createTime1 = this.date.date4[0] / 1000;
        this.searchForm.createTime2 = this.date.date4[1] / 1000;
      } else {
        this.searchForm.createTime1 = "";
        this.searchForm.createTime2 = "";
      }

      for (var i in this.searchForm) {
        param[i] = this.searchForm[i];
      }

      let t = getToken();
      let headers = { "x-token": t };
      axios({
        method: "post",
        url: this.$adminApiUrl + "/participate-log/export-log-excel", // 请求地址
        data: param, // 参数
        headers: headers,

        responseType: "blob", // 表明返回服务器返回的数据类型
      })
        .then((res) => {
          loadingDiv.close();
          console.log(res);
          let blob = new Blob([res.data], { type: "application/xlsx" });
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a"); // 创建a标签
          link.href = url;
          let timeStr = this.fmtDate(new Date(), "yyyyMMdd_hhmmss");
          link.download = "按搜索条件导出修改记录_" + timeStr + ".xlsx"; // 重命名文件
          link.click();
          URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
          loadingDiv.close();
          this.$alert("导出失败,请检查网络", {
            dangerouslyUseHTMLString: true,
            type: "warning",
          });
        });
    },
    search() {
      this.getDataList(1);
    },
    handleCurrentChange(pageNum) {
      this.getDataList(pageNum);
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getDataList(1);
    },
  },
};
</script>

<style scoped lang="scss">
// @import "~@/common/styles/element-variables.scss";
.search-box {
  background-color: #ffffff;
  min-height: 36px;
  line-height: 36px;
  padding-top: 2px;
  padding-bottom: 6px;
  .el-form-item {
    margin-bottom: 0px !important;
  }
  .el-form--inline {
    display: inline-block;
  }
}
.pagination-box {
  text-align: left;
  margin-top: 10px;
}
</style>
<style lang="scss">
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-dialog__header {
}
.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 0px;
}
.el-dialog__footer {
  padding-top: 0px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
